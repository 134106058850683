.icons{
    display: flex;
    gap: 10px;
}
.icons span{
    display: flex;
    gap: 10px;
}
.icons svg{
    font-size: 35px;
    background-color: #4782DA;
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    border: none;
    cursor: pointer;
}
.icons svg:hover{
    background-color: #233044;
}
/* pagination css */
.pagination{
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 20px;
}
.pagination button{
    background-color: #4782DA;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    border: none;
    cursor: pointer;
}
.pagination button:hover{
    background-color: #077;
}
.pagination .active{
    background-color: #077;
}
/* form css */
.form-width{
    width: 40%;
    padding: 50px;
    box-sizing: border-box;
}
/* footer text */
.footerText{
    width: 210px;
    word-wrap: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 !important;
}
@media screen and (max-width: 1400px){
    .form-width{
        width: 70%;
    }
}
@media screen and (max-width: 768px){
    .form-width{
        width: 100%;
    }
}
/* user List css*/
.css-1uvpa41-MuiGrid-root{
    display: block !important;
}
/* Navbar Css */
.NavLink{
    text-decoration: none;
    color: #233044;
}