.loadboard{
    width: 100%;
}
.headBar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #23304425;
}
.headBar h2{
    font-size: 14px;
    font-weight: 400;
    color: #233044;
}
.headBar h1{
    font-size: 24px;
    font-family: Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: 400;
    color: #233044;
    margin: 0;
    font-weight: 500;
}
.headBar button{
    background-color: #4782DA;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 50px;
    border-radius: 5px;
    border: none;
}
.headBar button:hover{
    background-color: #233044;
    cursor: pointer;
}
.bodyBar{
    margin-top: 30px;
    background-color: #fff;
    border-radius: 2px;
    padding: 50px;
    box-sizing: border-box;
    border: 1px solid #23304425;
}
.listHeader{
    display: grid;
    grid-template-columns: 0.5fr 1fr 2fr 4fr 1fr;
    grid-gap: 20px;
    align-items: center;
    border-bottom: 1px solid #23304425;
}
.listHeader h2{
    font-size: 14px;
    font-weight: 600;
    color: #233044;
}
.listBody{
    display: grid;
    grid-template-columns: 0.5fr 1fr 2fr 4fr 1fr;
    grid-gap: 20px;
    align-items: center;
    border-bottom: 1px solid #23304425;
}
.listBody:last-child{
    border: none;
}
.dimenList{
    display: flex;
    gap: 30px;
}
.listBody span{
    display: flex;
    gap: 10px;
}
.listBody svg{
    font-size: 35px;
    background-color: #4782DA;
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    border: none;
    cursor: pointer;
}
.listBody svg:hover{
    background-color: #233044;
}
.listBody h2{
    font-size: 15px;
    font-weight: 400;
    color: #233044;
}
/* Create Loads CSS */
.createLoads{
    width: 40%;
    padding: 50px;
    background-color: #fff;
    border: 1px solid #23304425;
    border-radius: 5px;
}
.createLoads h2{
    font-size: 20px;
    font-weight: 400;
    color: #233044;
    text-transform: capitalize;
}
.createLoads h3{
    font-size: 13px;
    font-weight: 400;
    color: #233044;
    text-transform: capitalize;
}
.headerArea{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media screen and (max-width : 1400px){
    .dimenList{
        flex-direction: column;
        gap: 5px;
    }
}
@media screen and (max-width : 768px){
    .listHeader{
        grid-gap: 5px;
    }
    .listHeader h2{
        font-size: 12px;
    }
    .listBody{
        grid-gap: 5px;
    }
    .listBody h2{
        font-size: 12px;
    }
    .dimenList p{
        font-size: 10px;
    }
    .dimenList p span{
        font-size: 12px;
    }
    .bodyBar{
        padding: 15px;
    }
    .listBody span{
        flex-direction: column;
    }
}